import ReactGA from 'react-ga';
/**
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * */
export const Event = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
