import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import '../../../assets/css/footer.css';
import {Event} from '../../GAClickEvent';
import facebookIcon from '../../../assets/images/header-footer/social/facebook-icon-2.svg';
import twitterIcon from '../../../assets/images/header-footer/social/twitter-icon-2.svg';
import linkedInIcon from '../../../assets/images/header-footer/social/linkedin-icon-2.svg';
import emailIcon from '../../../assets/images/header-footer/social/email-icon-2.svg';

const $ = typeof window !== `undefined` ? require('jquery') : null;

const backToTop = e => {
  e.preventDefault();
  window.scrollTo({top: 0, behavior: 'smooth'});
};

const Footer = ({showPortfolioSection, showContactSection}) => (
  <footer className="footer">
    {/* {showPortfolioSection ? (
      <div id="initial-section" className="initial-section">
        <div className="container-fluid">
          <div className="row content-row">
            <div className="col-12 mb-4 mb-md-0 col-md-8">
              <div className="heading latoBlack">We have worked with some great clients!</div>
            </div>
            <div className="col-12 col-md-4">
              <Link
                onClick={() =>
                  Event('See Our Portfolio', 'See Our Portfolio button is clicked', 'footer')
                }
                to="/portfolio"
                className="portfolio-link btn btn-outline-light-footer latoRegular px-lg-5"
                style={{border: '1px solid white'}}
              >
                See Our Portfolio
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : null} */}
    {showContactSection ? (
      <div id="signup-section" className="py-4 signup-section">
        <div className="footer-container">
          <div className="form-row center-property">
            <div className="signup-text col-12 mb-4 mb-md-0 col-md-8 ">
              <div className="heading latoBlack">We’re excited to hear about your product idea</div>
            </div>
            <div className="col-md-4">
              <Link
                className="btn btn-block btn-success-foot signup-btn"
                onClick={() =>
                  Event("Let's Get Started", "Let's talk Started button is clicked", 'footer')
                }
                to="/contact"
              >
                Let's talk
              </Link>
            </div>
          </div>
        </div>
      </div>
    ) : null}

    <div className="address-section">
      <div className="footer-container d-flex text-align-center col-12 w-100">
        <div className="justify-content-center text-align-center p-0 col-12 w-100">
          <div className="col-12 mt-5 mb-5 p-0">
            <div className="d-flex flex-row flex-wrap justify-content-between">
              <div className="col-lg-4 col-md-6 mb-3 p-0">
                <div className="latoBold heading text-white">Location</div>
                <ul>
                  <li className="city">
                    <address>
                      <span className="latoBold">USA</span>
                    </address>
                  </li>
                  <li className="city">
                    <address>
                      <span className="latoBold">Canada</span>
                    </address>
                  </li>
                  <li className="city">
                    <address>
                      <span className="latoBold">UAE</span>
                    </address>
                  </li>
                  <li className="city">
                    <address>
                      <span className="latoBold">Norway</span>
                    </address>
                  </li>
                  <li className="city">
                    <address>
                      <span className="latoBold">Ukraine</span>
                    </address>
                  </li>
                  <li className="city">
                    <address>
                      <span className="latoBold">Pakistan</span>
                    </address>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 mb-3 p-0 footer_address social-icons">
                <div className="latoBold heading text-white">Connect</div>

                <ul className="mb-0">
                  <li className="contact">Let's have a conversation.</li>
                  <li className="contact">
                    <a
                      onClick={() =>
                        Event('Dial Phone Number', 'Dial Phone Number button is clicked', 'Header')
                      }
                      className="nav-link nav-link-text pl-0"
                      style={{color: '#A0AABC'}}
                      href="tel:+14152340744"
                    >
                      <i className="fa fa-rotate-90 fa-phone fa-xs"></i>
                      &nbsp; +1 (415) 234-0744
                    </a>
                  </li>
                </ul>
                <div className="footer__social-links-container">
                  <a href="https://facebook.com/Tintash" target="_blank">
                    <img src={facebookIcon} alt="Tintash facebook" />
                  </a>

                  <a href="https://twitter.com/TintashApps" target="_blank">
                    <img src={twitterIcon} alt="Tintash twitter" />
                  </a>

                  <a href="https://www.linkedin.com/company/241494" target="_blank">
                    <img src={linkedInIcon} alt="Tintash linkedin" />
                  </a>

                  <a href="mailto:bd@tintash.com" target="_blank">
                    <img src={emailIcon} alt="Tintash Email" />
                  </a>
                </div>

                {/* <div className="latoBold heading text-white">Join our team</div>
                <ul className="mb-0">
                  <li className="contact">We're are more than just a workforce.</li>
                  <li className="contact">
                    <span className="mr-2">Careers</span>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 21 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.11358 5.34386L18.332 5.30243L14.6914 2.04963C14.446 1.82173 14.4256 1.4488 14.6505 1.20017C14.8755 0.951553 15.2437 0.930835 15.4891 1.15874L20.2955 5.50961C20.4182 5.6132 20.5 5.77895 20.5 5.9447C20.5 6.11045 20.4386 6.27619 20.3159 6.37979L15.5096 10.8343C15.3868 10.9378 15.2437 11 15.1005 11C14.9573 11 14.7937 10.9171 14.671 10.7928C14.446 10.5442 14.4665 10.1713 14.7119 9.94336L18.4343 6.5041L1.09313 6.54553C0.765885 6.54553 0.5 6.27619 0.5 5.9447C0.5 5.6132 0.765885 5.34386 1.11358 5.34386Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="0.3"
                      />
                    </svg>
                  </li>
                </ul> */}
              </div>

              <div className="col-lg-4 col-md-6 footer_address social-icons p-0">
                <div className="latoBold heading text-white">Links</div>
                <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                  <div className="contact">
                    <a
                      className="city"
                      href="https://tintash.zohorecruit.com/jobs/new-openings"
                      target="_blank"
                    >
                      <span className="mr-2">Careers</span>
                    </a>
                  </div>
                  <div className="city" style={{marginBottom: '4px'}}>
                    <Link to="/privacy" className="city">
                      <span className="latoRegular">Privacy Policy</span>&nbsp;
                    </Link>
                  </div>

                  <div className="city">
                    <Link to="/fraud-disclaimer" className="city">
                      <span className="latoRegular">Fraud Disclaimer</span>&nbsp;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bottom-bar clearfix">
      <div className="footer-container h-100 py-1">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-sm-8 col-12">
            <p className="latoBold mb-1 copy-right">Copyright 2022 Tintash - All Rights Reserved</p>
          </div>

          <div className="col-sm-4 col-12">
            <button
              type="submit"
              id="Top"
              className="latoBold text-white mb-0 back-top border-0 bg-transparent"
              onClick={backToTop}
            >
              Back To Top
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  showPortfolioSection: PropTypes.bool,
  showContactSection: PropTypes.bool,
};

Footer.defaultProps = {
  showPortfolioSection: true,
  showContactSection: true,
};

export default Footer;
